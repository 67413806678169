import React from "react"
import PageTemplate from '../components/pageTemplate'
import antipastos from '../data/anti-pasto'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="Anti-Pasto Range" img={data.fileName}>
    <div className="text-center">
      <h5>Raybek Foods Anti- Pasto ranges come in both bulk 2kg and 250g retail pack sizing to cater for all aspects of the market.</h5>
      <p>Olives, Sundried tomatoes as well as the finest quality Persian feta are all linked together to create divine combinations ready for your plater.</p>
    </div>
    <ProductDisplay products={antipastos}/>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "antipasto-banner2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
